import React from 'react';
import { FormattedMessage } from 'react-intl';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './OpenIDLoginButton.module.css';
import { SocialLoginButton } from '../Button/Button';
import { logoImageMobileURL } from '../../config/configBranding';
import useAuthWithPropertyRadar from '../../hooks/useAuthWithPropertyRadar';

const OpenIDLoginButton = props => {
  const { from, buttonClassName } = props;
  const showPropertyRadarLogin = !!process.env.REACT_APP_PROPERTY_RADAR_OPENID_CLIENT_ID;

  const { auth } = useAuthWithPropertyRadar({
    from,
    id: 'OpenIDLoginButton',
  });

  const rootClassName = classNames(buttonClassName || css.socialLoginButton);

  return showPropertyRadarLogin ? (
    <SocialLoginButton rootClassName={rootClassName} onClick={auth}>
      <img className={css.propertyRadarLogo} src={logoImageMobileURL} />
      <FormattedMessage id="AuthenticationPage.loginWithPropertyRadar" />
    </SocialLoginButton>
  ) : null;
};

OpenIDLoginButton.defaultProps = {
  from: null,
  buttonClassName: null,
};

OpenIDLoginButton.propTypes = {
  buttonClassName: string,
  from: string,
};

export default OpenIDLoginButton;
