import { listingConfigApi } from '../util/axiosApi';
import { storableError } from '../util/errors';

// ================ Action types ================ //

const CONFIG_REQUEST = 'app/listingConfig/REQUEST';
const CONFIG_SUCCESS = 'app/listingConfig/SUCCESS';
const CONFIG_ERROR = 'app/listingConfig/ERROR';

// ================ Reducer ================ //

const initialState = {
  config: {},
  inProgress: false,
  error: null,
};

export default function listingConfigReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CONFIG_REQUEST:
      return { ...state, inProgress: true, error: null };
    case CONFIG_SUCCESS:
      return {
        ...state,
        config: payload,
        inProgress: false,
      };
    case CONFIG_ERROR:
      return { ...state, inProgress: false, error: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

// ================ Thunks ================ //

export const fetchListingConfig = () => async dispatch => {
  try {
    dispatch({
      type: CONFIG_REQUEST,
    });

    const response = await listingConfigApi();
    const config = response.data;
    dispatch({
      type: CONFIG_SUCCESS,
      payload: config,
    });
    return config;
  } catch (error) {
    dispatch({ type: CONFIG_ERROR, payload: storableError(error?.response || error) });
    return {};
  }
};
