import React from 'react';
import { bool, object, string } from 'prop-types';

import { intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { FieldTextInput, FieldSelect } from '..';

import css from './StripePaymentAddress.module.css';
import { usStates } from '../../config/configStripe';

const StripePaymentAddress = props => {
  const { className, intl, disabled, form, fieldId, card } = props;

  const postalCodeLabel = intl.formatMessage({ id: 'StripePaymentAddress.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.postalCodeRequired',
    })
  );

  const stateLabel = intl.formatMessage({ id: 'StripePaymentAddress.stateLabel' });
  const statePlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.statePlaceholder' });
  const stateRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.stateRequired',
    })
  );

  const handleOnChange = event => {
    const { value } = event.target;
    form.change('postal', value);
    card.update({ value: { postalCode: value } });
  };

  return (
    <div className={className || css.root}>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.postalCode`}
          name="postal"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing postal-code"
          label={postalCodeLabel}
          placeholder={postalCodePlaceholder}
          validate={postalCodeRequired}
          onUnmount={() => form.change('postal', undefined)}
          onChange={event => handleOnChange(event)}
        />
        <FieldSelect
          id={`${fieldId}.state`}
          name="state"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level1"
          label={stateLabel}
          placeholder={statePlaceholder}
          validate={stateRequired}
          onUnmount={() => form.change('state', undefined)}
        >
          <option disabled value="">
            {statePlaceholder}
          </option>
          {usStates.map(state => (
            <option key={state.key} value={state.key}>
              {state.label}
            </option>
          ))}
        </FieldSelect>
      </div>
      <div className={css.formRow} />
    </div>
  );
};
StripePaymentAddress.defaultProps = {
  disabled: false,
  fieldId: null,
};

StripePaymentAddress.propTypes = {
  disabled: bool,
  form: object.isRequired,
  fieldId: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default StripePaymentAddress;
