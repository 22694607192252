import { convertMoneyToNumber } from '../../util/currency';

import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

export const customFormatMoney = (intl, value) => {
  if (!(value instanceof Money)) {
    throw new Error('Value must be a Money type');
  }
  const valueAsNumber = convertMoneyToNumber(value);
  if (!valueAsNumber) {
    return intl.formatMessage({ id: 'DisplayPrice.free' });
  }

  // See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
  const numberFormatOptions = {
    style: 'currency',
    currency: value.currency,
    currencyDisplay: 'symbol',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return intl.formatNumber(valueAsNumber, numberFormatOptions);
};

export const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = customFormatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

export const getListingTypeLabel = (listingType, config) => {
  const listingTypeFound = config.listing.listingTypes.find(
    type => type.listingType === listingType
  );
  return listingTypeFound ? listingTypeFound.label : null;
};
