import { kebabCase, uniqBy } from 'lodash';
import { types as sdkTypes } from './sdkLoader';
import { unitDivisor } from './currency';

const { Money } = sdkTypes;

export const RANGE_DISPLAY_STATE = 'RANGE';

export const FREE_STATE = 'Free';

export const listingHasVariantPricing = listing => {
  const { maxPrice } = listing.attributes.publicData;
  return !!maxPrice;
};

export const getListingMinAndMaxPrice = listing => {
  const { currency } = listing.attributes.price;
  const minorUnit = unitDivisor(currency);
  const { minPrice = 0, maxPrice = 0 } = listing.attributes.publicData || {};

  return {
    minPrice: Number(minPrice) * minorUnit,
    maxPrice: Number(maxPrice) * minorUnit,
  };
};

export const convertPriceToMoney = (price, currency) => new Money(price, currency);

export const listingHasFreeVariantPricing = listing => {
  const { minPrice, maxPrice } = listing.attributes.publicData;
  return minPrice === 0 || maxPrice === 0;
};

export const getListingVariantName = listing => {
  const { variant1 = '', variant2 = '' } = listing.attributes.publicData;
  return {
    variant1,
    variant2,
  };
};

export const createVariantMapping = (variants, variantKey) =>
  uniqBy(variants, i => i[variantKey]).map(item => ({
    label: item[variantKey],
    value: kebabCase(item[variantKey]),
  }));

export const mappingVariantsData = variants => {
  if (!variants || !Array.isArray(variants) || variants.length === 0) return [];

  return {
    variant1: createVariantMapping(variants, 'variant1'),
    variant2: createVariantMapping(variants, 'variant2'),
  };
};

export const getPriceDisplayState = (priceDisplay, minPriceDisplay, maxPriceDisplay) => {
  const isFree = priceDisplay.formattedPrice === FREE_STATE;
  const isMinPriceFree = minPriceDisplay.formattedPrice === FREE_STATE;
  const isMaxPriceFree = maxPriceDisplay.formattedPrice === FREE_STATE;

  // Check if prices are different and main price is free or minimum price is not free and maximum price is not free
  if (
    minPriceDisplay.formattedPrice !== maxPriceDisplay.formattedPrice &&
    (isFree || !isMinPriceFree) &&
    !isMaxPriceFree
  ) {
    return RANGE_DISPLAY_STATE;
  }

  return null;
};

export const formatVariantPrice = (price, currency) => {
  const minorUnit = unitDivisor(currency);
  return new Money(Number(price) * minorUnit, currency);
};

export const checkListingPriceIsFree = listing => {
  if (!listing) {
    return false;
  }
  const {
    price,
    publicData: { maxPrice },
  } = listing?.attributes || {};
  return price?.amount === 0 && (maxPrice === 0 || !maxPrice);
};
