import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';

import {
  FREE_STATE,
  convertPriceToMoney,
  getListingMinAndMaxPrice,
  getPriceDisplayState,
} from '../../util/listings.utils';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '..';

import css from './ListingCard.module.css';
import { getListingTypeLabel, priceData } from './ListingCard.utils';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const getPriceDisplay = (listing, marketplaceCurrency, intl) => {
  const price = listing?.attributes?.price;
  const priceDisplay = priceData(price, marketplaceCurrency, intl);
  const { minPrice, maxPrice } = getListingMinAndMaxPrice(listing);
  const minPriceDisplay = priceData(
    convertPriceToMoney(minPrice, marketplaceCurrency),
    marketplaceCurrency,
    intl
  );
  const maxPriceDisplay = priceData(
    convertPriceToMoney(maxPrice, marketplaceCurrency),
    marketplaceCurrency,
    intl
  );

  const priceDisplayState = getPriceDisplayState(priceDisplay, minPriceDisplay, maxPriceDisplay);
  if (!priceDisplayState) {
    return (
      <div className={css.priceContainer}>
        <div className={css.priceValue} title={priceDisplay.priceTitle}>
          {priceDisplay.formattedPrice}
        </div>
      </div>
    );
  }
  const minPriceFormatted =
    minPriceDisplay.formattedPrice === FREE_STATE ? '$0' : minPriceDisplay.formattedPrice;
  const maxPriceFormatted = maxPriceDisplay.formattedPrice;
  return (
    <div className={css.priceContainer}>
      <div className={css.priceValue} title={priceDisplay.priceTitle}>
        {intl.formatMessage(
          {
            id: 'ListingCard.priceRange',
          },
          {
            minPrice: minPriceFormatted,
            maxPrice: maxPriceFormatted,
          }
        )}
      </div>
    </div>
  );
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData } = currentListing.attributes;
  const { listingType } = publicData || {};
  const listingTypeLabel = getListingTypeLabel(listingType, config);
  const priceDisplay = getPriceDisplay(currentListing, config.currency, intl);
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1 } = config.layout.listingImage;

  const variantPrefix = 'scaled';

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {listingTypeLabel ? <div className={css.listingType}>{listingTypeLabel}</div> : null}
        </div>
        <div className={css.price}>{priceDisplay}</div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
