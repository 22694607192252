import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteConfiguration } from '../context/routeConfigurationContext';
import { apiBaseUrl } from '../util/api';
import { pathByRouteName } from '../util/routes';
import { fetchCurrentUser } from '../ducks/user.duck';
import { authInfo } from '../ducks/auth.duck';

const keepOnSuccess = {};

const useAuthWithPropertyRadar = ({ from, onSuccess, id = Date.now() }) => {
  const dispatch = useDispatch();

  const onFetchCurrentUser = useCallback(async () => {
    await dispatch(authInfo());
    return dispatch(fetchCurrentUser());
  }, [dispatch]);
  const routeConfiguration = useRouteConfiguration();

  const handleOnSuccess = () =>
    onFetchCurrentUser().then(() => {
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    });

  const getDefaultRoutes = () => {
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('AuthenticationSuccessPage', routeConfiguration);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routeConfiguration);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };

  const messageEventHandler = useCallback(
    async e => {
      if (e.data === 'OpenID_Login_Success') {
        keepOnSuccess[id]?.();
        delete keepOnSuccess[id];
      }
    },
    [id]
  );

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
  }, [messageEventHandler]);

  const popupCenter = ({ url, title, w, h }) => {
    if (typeof window === 'undefined') {
      return;
    }

    const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
    const height =
      window.innerHeight || document.documentElement.clientHeight || window.screen.height;

    const left = (width - w) / 2;
    const top = (height - h) / 2;
    const newWindow = window.open(url, title, `width=${w},height=${h},top=${top},left=${left}`);

    if (window.focus) newWindow.focus();
  };

  const auth = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    popupCenter({
      url: `${baseUrl}/api/auth/openid?${fromParam}${defaultReturnParam}${defaultConfirmParam}`,
      title: 'Login to Marketplace',
      w: 600,
      h: 600,
    });
    keepOnSuccess[id] = handleOnSuccess;
  };

  return {
    auth,
  };
};

export default useAuthWithPropertyRadar;
