import React, { useCallback, useMemo, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';

import moment from 'moment';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_ITEM } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import { isBookingProcess, resolveLatestProcessName } from '../../transactions/transaction';

// eslint-disable-next-line import/no-cycle
import { ModalInMobile, PrimaryButton, H1, H2, SecondaryButton, ExternalLink } from '..';
import renderMarkdown from '../../containers/PageBuilder/markdownProcessor';

import css from './OrderPanel.module.css';
import {
  FREE_STATE,
  convertPriceToMoney,
  formatVariantPrice,
  getListingMinAndMaxPrice,
  getPriceDisplayState,
  listingHasVariantPricing,
} from '../../util/listings.utils';
import VariantSelect from '../../containers/ListingPage/VariantSelect/VariantSelect';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = price.amount ? formatMoney(intl, price) : 'Free';
    return { formattedPrice, priceTitle: formattedPrice };
  }
  if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const getPriceDisplay = (listing, marketplaceCurrency, intl, hasPriceClassName = true) => {
  const price = listing?.attributes?.price;
  const priceDisplay = priceData(price, marketplaceCurrency, intl);
  const { minPrice, maxPrice } = getListingMinAndMaxPrice(listing);
  const minPriceDisplay = priceData(
    convertPriceToMoney(minPrice, marketplaceCurrency),
    marketplaceCurrency,
    intl
  );
  const maxPriceDisplay = priceData(
    convertPriceToMoney(maxPrice, marketplaceCurrency),
    marketplaceCurrency,
    intl
  );

  const priceDisplayState = getPriceDisplayState(priceDisplay, minPriceDisplay, maxPriceDisplay);

  if (!priceDisplayState) {
    return (
      <div className={css.priceContainer}>
        <div className={css.price}>{priceDisplay.formattedPrice}</div>
      </div>
    );
  }

  const minPriceFormatted =
    minPriceDisplay.formattedPrice === FREE_STATE ? '$0' : minPriceDisplay.formattedPrice;
  const maxPriceFormatted = maxPriceDisplay.formattedPrice;

  const classes = classNames({
    [css.price]: hasPriceClassName,
  });
  return (
    <div className={css.priceContainer}>
      <div className={classes}>
        {intl.formatMessage(
          {
            id: 'ListingCard.priceRange',
          },
          {
            minPrice: minPriceFormatted,
            maxPrice: maxPriceFormatted,
          }
        )}
      </div>
    </div>
  );
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const PriceMissing = () => {
  return (
    <p className={css.error}>
      <FormattedMessage id="OrderPanel.listingPriceMissing" />
    </p>
  );
};

const InvalidCurrency = () => {
  return (
    <p className={css.error}>
      <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
    </p>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    history,
    location,
    intl,
    marketplaceCurrency,
    listingConfig,
    showVariantSelect,
    handleChangeVariantData,
  } = props;

  const { listingFieldsToRender } = listingConfig;
  const [variantPrice, setVariantPrice] = useState(null);
  const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const unitType = listing?.attributes?.publicData?.unitType;
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;
  const price = listing?.attributes?.price;
  const priceDisplay = getPriceDisplay(listing, marketplaceCurrency, intl);
  const priceDisplayMobile = getPriceDisplay(listing, marketplaceCurrency, intl, false);
  const showPriceMissing = !price;
  const showInvalidCurrency = price?.currency !== marketplaceCurrency;
  const listingHasVariant = listingHasVariantPricing(listing);

  const shouldShowVariantSelect = showVariantSelect && listingHasVariant;

  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.

  const { description = '', createdAt, publicData, metadata = {} } = listing?.attributes || {};

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const handleChangeVariantPrice = priceVal => {
    setVariantPrice(priceVal);
  };

  const formattedVariantPrice = variantPrice ? (
    <div className={css.price}>
      {formatMoney(intl, formatVariantPrice(variantPrice, marketplaceCurrency))}
    </div>
  ) : null;

  const handleShareProduct = useCallback(async () => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line import/no-extraneous-dependencies
      import('clipboard-polyfill').then(({ writeText }) => {
        writeText(window.location.href).then(() => {
          alert('Link copied to clipboard');
        });
      });
    }
  }, []);

  const panel = useMemo(() => {
    if (showPriceMissing) {
      return <PriceMissing />;
    }
    if (showInvalidCurrency) {
      return <InvalidCurrency />;
    }

    return (
      <>
        <div className={css.submitButton}>
          <PrimaryButton
            type="submit"
            disabled={!variantPrice && listingHasVariant}
            onClick={() => onSubmit({})}
          >
            Buy Now
          </PrimaryButton>
        </div>
        <div className={css.shareButton}>
          <SecondaryButton type="button" onClick={handleShareProduct}>
            <FormattedMessage id="ProductOrderForm.shareListing" />
          </SecondaryButton>
        </div>
      </>
    );
  }, [
    handleShareProduct,
    onSubmit,
    showInvalidCurrency,
    showPriceMissing,
    variantPrice,
    listingHasVariant,
  ]);

  const ctaButtonTitle = useMemo(() => {
    if (isBooking) {
      return <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />;
    }
    if (isOutOfStock) {
      return <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />;
    }
    return <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />;
  }, [isBooking, isOutOfStock]);

  const pickListingFields = (filteredConfigs, config) => {
    const { key, label, options = [] } = config;
    const publicDataValue = key === 'createdAt' ? createdAt : publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (typeof value !== 'undefined') {
      if (options.length) {
        const isMultiEnum = Array.isArray(value);
        const optionConfig = isMultiEnum
          ? options.filter(op => value.includes(op.key))
          : options.find(o => o.key === value);
        return filteredConfigs.concat({
          key,
          value: optionConfig?.label ?? optionConfig?.map(op => op.label).join(', ') ?? '',
          label,
        });
      }
      if (key === 'createdAt') {
        return filteredConfigs.concat({ key, value: moment(publicDataValue).format('LL'), label });
      }
      if (key === 'purchasedTimes') {
        return filteredConfigs.concat({
          key,
          value: intl.formatNumber(value, { notation: 'compact' }),
          label,
        });
      }
    }

    return filteredConfigs;
  };

  const existingListingFields = listingFieldsToRender.reduce(pickListingFields, []);
  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div>
        <div className={css.orderHeading}>
          {titleDesktop || <H2 className={titleClasses}>{title}</H2>}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div>
        {priceDisplay ? (
          <div className={css.priceContainer}>{formattedVariantPrice || priceDisplay}</div>
        ) : null}
        <p className={css.description}>
          {renderMarkdown(description, {
            a: ExternalLink,
          })}
        </p>
        {shouldShowVariantSelect ? (
          <div className={css.variantWrapper}>
            <VariantSelect
              listing={listing}
              handleChangeVariantPrice={handleChangeVariantPrice}
              handleChangeVariantData={handleChangeVariantData}
            />
          </div>
        ) : null}
        <ul className={css.details}>
          {existingListingFields.map(detail => (
            <li key={detail.key} className={css.detailsRow}>
              <span className={css.detailLabel}>{detail.label}</span>
              <span className={css.detailValue}>{detail.value}</span>
            </li>
          ))}
        </ul>
        <div className={css.author}>
          <span className={css.providerNameLinked}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
          </span>
          <span className={css.providerNamePlain}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
          </span>
        </div>
        {panel}
      </ModalInMobile>

      <div className={css.openOrderForm}>
        <div className={css.priceContainerInCTA}>
          {priceDisplayMobile ? (
            <div className={css.priceContainer}>{formattedVariantPrice || priceDisplayMobile}</div>
          ) : null}
          <div className={css.perUnitInCTA}>
            <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
          </div>
        </div>

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <PrimaryButton
            onClick={() => openOrderModal(isOwnListing, isClosed, history, location)}
            disabled={isOutOfStock}
          >
            {ctaButtonTitle}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  titleDesktop: null,
  listing: null,
  lineItemUnitType: null,
  showVariantSelect: false,
  handleChangeVariantData: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  onManageDisableScrolling: func.isRequired,

  marketplaceCurrency: string.isRequired,
  lineItemUnitType: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  listingConfig: object.isRequired,
  showVariantSelect: bool,
  handleChangeVariantData: func,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
