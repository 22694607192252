import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import { func } from 'prop-types';
import { fetchVariantsPrice } from '../ListingPage.duck';
import { propTypes } from '../../../util/types';

import css from './VariantSelect.module.css';
import { IconSpinner } from '../../../components';
import {
  createVariantMapping,
  getListingVariantName,
  mappingVariantsData,
} from '../../../util/listings.utils';

const VariantSelect = ({ listing, handleChangeVariantPrice, handleChangeVariantData }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [selectedVariant1, setSelectedVariant1] = useState(null);
  const [selectedVariant2, setSelectedVariant2] = useState(null);
  const [variant2Options, setVariant2Options] = useState(null);
  const variantsPrice = useSelector(state => state.ListingPage.variantsPrice);
  const fetchVariantsPriceInProgress = useSelector(
    state => state.ListingPage.fetchVariantsPriceInProgress
  );
  const fetchVariantsPriceError = useSelector(state => state.ListingPage.fetchVariantsPriceError);

  const { variant1: pubVariant1, variant2: pubVariant2 } = getListingVariantName(listing);
  const { variant1: variant1Options } = mappingVariantsData(variantsPrice);

  const handleVariant1Changed = variant1Value => {
    setSelectedVariant1(variant1Value);
    const filteredOptions = variantsPrice.filter(
      variant => variant.variant1 === variant1Value.label
    );
    if (pubVariant1 && pubVariant2 === '') {
      handleChangeVariantData({ variant1: variant1Value.label });
      return handleChangeVariantPrice(filteredOptions[0].price);
    }
    handleChangeVariantPrice(null);
    setVariant2Options(createVariantMapping(filteredOptions, 'variant2'));
    setSelectedVariant2(null);
  };
  const handleVariant2Changed = variant2Value => {
    setSelectedVariant2(variant2Value);
    const variantData = { variant1: selectedVariant1.label, variant2: variant2Value.label };
    const selectedVariant = variantsPrice.find(
      variant =>
        variant.variant1 === variantData.variant1 && variant.variant2 === variantData.variant2
    );
    const price = selectedVariant ? selectedVariant.price : 0;
    handleChangeVariantData(variantData);
    handleChangeVariantPrice(price);
  };
  useEffect(() => {
    const fetchVariants = async () => {
      await dispatch(fetchVariantsPrice(listing.id.uuid));
    };
    fetchVariants();
  }, [dispatch, listing.id.uuid]);
  return (
    <div className={css.root}>
      {fetchVariantsPriceError ? (
        <div className={css.error}>
          {intl.formatMessage({
            id: 'VariantSelect.fetchError',
          })}
        </div>
      ) : null}
      {fetchVariantsPriceInProgress ? (
        <IconSpinner />
      ) : (
        <div className={css.dropdownWrapper}>
          <div className={css.dropdownItem}>
            <div className={css.itemHeader}>{pubVariant1}</div>
            <Select
              options={variant1Options}
              onChange={handleVariant1Changed}
              value={selectedVariant1}
            />
          </div>
          {pubVariant2 !== '' && (
            <div className={css.dropdownItem}>
              <div className={css.itemHeader}>{pubVariant2}</div>
              <Select
                value={selectedVariant2}
                options={variant2Options}
                isDisabled={!variant2Options}
                onChange={handleVariant2Changed}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

VariantSelect.propTypes = {
  listing: propTypes.listing.isRequired,
  handleChangeVariantPrice: func.isRequired,
  handleChangeVariantData: func.isRequired,
};

export default VariantSelect;
