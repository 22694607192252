export const transitions = {
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT: 'transition/request',
  MARK_UNLOCK: 'transition/mark-unlock',
  MARK_UNLOCK_FAIL: 'transition/mark-unlock-fail',
  COMPLETE: 'transition/complete',
  REVIEW_BY_CUSTOMER: 'transition/review-by-customer',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PURCHASED: 'purchased',
  UNLOCKED: 'unlocked',
  FAILED: 'failed',
  COMPLETED: 'completed',
  REVIEWED: 'reviewed',
};
export const graph = {
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PURCHASED,
      },
    },
    [states.REVIEWED]: {
      type: 'final',
    },
    [states.INQUIRY]: {},
    [states.PURCHASED]: {
      on: {
        [transitions.MARK_UNLOCK]: states.UNLOCKED,
        [transitions.MARK_UNLOCK_FAIL]: states.FAILED,
      },
    },
    [states.UNLOCKED]: {
      on: {
        [transitions.COMPLETE]: states.COMPLETED,
      },
    },
    [states.FAILED]: {},
    [states.COMPLETED]: {
      on: {
        [transitions.REVIEW_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
  },
};

export const isRelevantPastTransition = transition => {
  return [
    transitions.REQUEST_PAYMENT,
    transitions.MARK_UNLOCK_FAIL,
    transitions.COMPLETE,
    transitions.REVIEW_BY_CUSTOMER,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return transitions.REVIEW_BY_CUSTOMER === transition;
};

const privilegeTransitions = [transitions.REQUEST_PAYMENT];

export const isPrivileged = transition => {
  return privilegeTransitions.includes(transition);
};

export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.COMPLETE,
    transitions.REVIEW_BY_CUSTOMER,
    transitions.EXPIRE_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

export const isRefunded = transition => {
  return transitions.MARK_UNLOCK_FAIL === transition;
};

export const statesNeedingProviderAttention = [];
